// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container {
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.4); /* Doubled shadow intensity */
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px); /* Adds a blur effect */
    background-color: rgba(200, 200, 200, 0.8); /* Light gray see-through background */
    color: var(--text-color); /* Text color based on the theme */
}

.profile-container.light-theme {
    background-color: rgba(255, 255, 255, 0.9); /* Slightly lighter gray for light theme */
    color: #333; /* Dark text color */
}

.profile-container.dark-theme {
    background-color: rgba(50, 50, 50, 0.9); /* Slightly darker gray for dark theme */
    color: #f1f1f1; /* Light text color */
}

.profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: #333; /* Default background if no image */
}

`, "",{"version":3,"sources":["webpack://./src/styles/Profile.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,0CAA0C,EAAE,6BAA6B;IACzE,mCAA2B;YAA3B,2BAA2B,EAAE,uBAAuB;IACpD,0CAA0C,EAAE,sCAAsC;IAClF,wBAAwB,EAAE,kCAAkC;AAChE;;AAEA;IACI,0CAA0C,EAAE,0CAA0C;IACtF,WAAW,EAAE,oBAAoB;AACrC;;AAEA;IACI,uCAAuC,EAAE,wCAAwC;IACjF,cAAc,EAAE,qBAAqB;AACzC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,sBAAsB,EAAE,mCAAmC;AAC/D","sourcesContent":[".profile-container {\n    padding: 1rem;\n    border-radius: 8px;\n    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.4); /* Doubled shadow intensity */\n    backdrop-filter: blur(10px); /* Adds a blur effect */\n    background-color: rgba(200, 200, 200, 0.8); /* Light gray see-through background */\n    color: var(--text-color); /* Text color based on the theme */\n}\n\n.profile-container.light-theme {\n    background-color: rgba(255, 255, 255, 0.9); /* Slightly lighter gray for light theme */\n    color: #333; /* Dark text color */\n}\n\n.profile-container.dark-theme {\n    background-color: rgba(50, 50, 50, 0.9); /* Slightly darker gray for dark theme */\n    color: #f1f1f1; /* Light text color */\n}\n\n.profile-picture {\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    object-fit: cover;\n    margin-top: 1rem;\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n    background-color: #333; /* Default background if no image */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
